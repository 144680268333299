import { Collapse, Popover, Modal, Dropdown } from "bootstrap";
import $ from "jquery";
import "dirrty";
import "jquery-lazy";

import * as alerts from "./alerts";
import * as dynamic from "./dynamic";
import * as utils from "./utils";

export {
    DEBUG,
    init,
    initPopovers,
    initTextWithCopy,
    setDirrtyFormClean,
    runtimeContext,
    resetDirrtyForm,
    setDropdownShown,
    setMobileMenuOpen,
    showSidebar,
}


let DEBUG = false;
let resizeTimeoutID: number | null = null;
let skolonButtonContainer: HTMLElement | null = null;
let runtimeContext: RuntimeContext;


/**
 * Setup treatment of "dirty" forms (data has been changed and not saved).
 * Default behaviour: Disable submit buttons when form is "clean", enable them
 * when form is "dirty". Display warning alert if user tries to leave page
 * with dirty forms.
 *
 * Should be run max one time per element, otherwise the listeners will just
 * be piling up.
 *
 * Set "data-dirrty-no-warning" on form element to disable the warning.
 * Set "data-dirrty-disabled" to disable all the functionality.
 */
function initDirrty(form: HTMLFormElement) {
    if (form.dataset.dirrtyDisabled === undefined) {
        $(form).dirrty({ preventLeaving: form.dataset.dirrtyNoWarning === undefined }).on("dirty", () => {
            form.querySelectorAll("input[type='submit'], button[type='submit']").forEach(elem => {
                elem.classList.remove("disabled");
                if (elem instanceof HTMLInputElement || elem instanceof HTMLButtonElement) elem.disabled = false;
            });
        }).on("clean", () => {
            form.querySelectorAll("input[type='submit'], button[type='submit']").forEach(elem => {
                elem.classList.add("disabled");
                if (elem instanceof HTMLInputElement || elem instanceof HTMLButtonElement) elem.disabled = true;
            });
        });
    }
}


/**
 * Uses an undocumented feature to force Dirrty to consider the form clean
 * in its present state. Let's hope that feature remains.
 */
function setDirrtyFormClean(form: HTMLFormElement) {
    if (form.dataset.dirrtyDisabled === undefined) $(form).dirrty("setClean");
}


/**
 * Resets a form, once initialised with Dirrty, to hold its original values.
 */
function resetDirrtyForm(form: HTMLFormElement) {
    if (form.dataset.dirrtyDisabled === undefined) {
        for (const elem of form.elements) {
            if ((elem instanceof HTMLInputElement || elem instanceof HTMLTextAreaElement) && elem.dataset.isDirrty == "true") {
                if (elem instanceof HTMLInputElement && (elem.type == "radio" || elem.type == "checkbox"))
                    elem.checked = elem.dataset.dirrtyInitialValue == "checked";
                else elem.value = elem.dataset.dirrtyInitialValue || "";
                elem.dispatchEvent(new Event("change"));
            }
        }
        setDirrtyFormClean(form);
    }
}


/**
 * Form init stuff, should only be done once per form.
 *
 * - Initialize jQuery.dirrty if not explicitly disabled
 * - Disable submit buttons when submitted (unless they have a value), so
 * user can't press submit twice
 */
function initForm(form: HTMLFormElement) {
    initDirrty(form);
    form.addEventListener("submit", event => {
        if (event.target instanceof Element) {
            event.target.querySelectorAll("input[type='submit']:not([value]), button[type='submit']:not([value])").forEach(elem => {
                if (elem instanceof HTMLInputElement || elem instanceof HTMLButtonElement) elem.disabled = true;
            });
        }
    });
}


/**
 * Set class="active" for all elements where any of these are true:
 *  - viewName is in their data-active-if-view attribute, but not in their
 *    data-not-active-if-view attribute
 *  - appName is in their data-active-if-app attribute
 *  - Their data-active-if-view-contains attribute contains a substring of
 *    viewName
 *  - path is in their data-active-if-path attribute
 *  - Their data-active-if-path-startswith attribute starts with path
 *
 * Remove class="active" for all elements that have viewName in their
 * data-not-active-if-view attribute.
 */
function initActiveClasses(root: ParentNode, viewName: string, appName: string, path: string) {
    // viewName is in data-active-if-view but not in data-not-active-if-view:
    root.querySelectorAll(
        `[data-active-if-view~="${viewName}"]:not([data-not-active-if-view~="${viewName}"])`
    ).forEach(elem => elem.classList.add("active"));
    // viewName is in data-not-active-if-view:
    root.querySelectorAll(`[data-not-active-if-view~="${viewName}"]`).forEach(elem => elem.classList.remove("active"));
    // appName is in data-active-if-app:
    root.querySelectorAll(`[data-active-if-app~="${appName}"]`).forEach(elem => elem.classList.add("active"));
    // Part of viewName is in data-active-if-view-contains:
    root.querySelectorAll("[data-active-if-view-contains]").forEach(elem => {
        if (
            elem instanceof HTMLElement &&
            elem.dataset.activeIfViewContains &&
            viewName.indexOf(elem.dataset.activeIfViewContains) != -1
        ) elem.classList.add("active");
    });
    root.querySelectorAll(`[data-active-if-path~="${path}"]`).forEach(elem => elem.classList.add("active"));
    root.querySelectorAll("[data-active-if-path-startswith]").forEach(elem => {
        if (
            elem instanceof HTMLElement &&
            elem.dataset.activeIfPathStartswith &&
            path.startsWith(elem.dataset.activeIfPathStartswith)
        ) elem.classList.add("active");
    });
}


/**
 * Semi-transparent backdrop when mobile menu is shown.
 * Click on backdrop element hides menu.
 * Also make content "behind" menu unscrollable while showing menu, and set
 * menu's max height to the space currently available on the user's viewport.
 */
function initMobileNavbar() {
    const navbar = document.getElementById("top-navbar");
    const navbarBackdrop = document.getElementById("navbar-backdrop");

    if (navbar) {
        // On menu show: show backdrop, hide overflow on body, set max height
        navbar.addEventListener("show.bs.collapse", () => {
            navbarBackdrop?.classList.add("show");
            document.body.classList.add("navbar-open");
        });

        // On menu hide: begin fading out backdrop
        navbar.addEventListener("hide.bs.collapse", () => {
            navbarBackdrop?.classList.add("fading");
        });

        // On menu hidden: hide backdrop, reset overflow on body
        navbar.addEventListener("hidden.bs.collapse", () => {
            navbarBackdrop?.classList.remove("show", "fading");
            document.body.classList.remove("navbar-open");
        });

        // Hide menu on backdrop click
        navbarBackdrop?.addEventListener("click", () => {
            Collapse.getOrCreateInstance(navbar, { toggle: false }).hide();
        });
    }
}


function initPopovers(root: Document | HTMLElement) {
    root.querySelectorAll("[data-bs-toggle='popover']").forEach(elem => {
        const popover = Popover.getOrCreateInstance(elem);

        if (elem instanceof HTMLElement) {
            if (elem.dataset.bsContent?.startsWith(".") || elem.dataset.bsContent?.startsWith("#")) {
                const contentElement = root.querySelector(`${elem.dataset.bsContent} .content`);
                if (contentElement) popover.setContent({".popover-body": contentElement});
            }
        }
    });
}


function initTextWithCopy(root: Document | HTMLElement) {
    root.querySelectorAll(".text-with-copy").forEach(container => {
        const iconContainer = container.getElementsByClassName("input-group-icon").item(0);
        const input = container.getElementsByTagName("input").item(0);

        if (iconContainer instanceof HTMLElement && input instanceof HTMLInputElement) {
            iconContainer.addEventListener("click", () => {
                const popover = Popover.getOrCreateInstance(iconContainer);
                const popoverMessageOrig = iconContainer.dataset.bsContent || "";

                navigator.clipboard.writeText(input.value).then(
                    () => popover.setContent({".popover-body": gettext("Copied!")}),
                    () => popover.setContent({
                        ".popover-body": `<span class="text-danger">${gettext("Copying denied!")}</span>`
                    }),
                );

                window.setTimeout(() => {
                    popover.hide();
                    popover.setContent({ ".popover-body": popoverMessageOrig });
                }, 2000);
            });
        }
    });
}


function initWindowListeners() {
    // Fire 'resizeend' event on window 500 ms after last 'resize' event.
    // Reason: Some browsers fire 'resize' events continuously during viewport
    // resizing, which results in lots of unnecessary work if we have attached
    // handlers to that event. So use this event instead.
    window.onresize = () => {
        if (resizeTimeoutID) window.clearTimeout(resizeTimeoutID);
        resizeTimeoutID = window.setTimeout(() => {
            window.dispatchEvent(new UIEvent("resizeend"));
        }, 500);
    };

    window.onscroll = () => {
        // Only on desktop:
        if (utils.windowReachesBreakpoint("lg")) {
            const sidebar = document.getElementById("sidebar");

            // Resize sidebar with page scroll:
            if (sidebar) {
                const top = sidebar.getBoundingClientRect().top;
                sidebar.style.height = `calc(100vh - ${top}px)`;
            }
        }

        utils.setDropdownMaxHeights(document);
    }

    window.addEventListener("resizeend", renderSkolonButton);
}


/**
 * Hack to enable Skolon menu buttons on 2 places in DOM for responsiveness.
 */
function renderSkolonButton() {
    if (window.SkolonMenuButton) {
        const mobile = document.getElementsByClassName("skolon-button-container skolon-button-mobile").item(0);
        const desktop = document.getElementsByClassName("skolon-button-container skolon-button-desktop").item(0);
        let currentContainer: Element | null;
        let otherContainer: Element | null;

        if (utils.getCurrentBreakpoints().indexOf("lg") === -1) {
            currentContainer = mobile;
            otherContainer = desktop;
        } else {
            currentContainer = desktop;
            otherContainer = mobile;
        }

        if (currentContainer instanceof HTMLElement && currentContainer != skolonButtonContainer) {
            skolonButtonContainer = currentContainer;
            currentContainer.innerHTML = `<div class="skolon-menu-button" data-shadow="false"></div>`;
        }

        if (otherContainer instanceof Element) {
            otherContainer.innerHTML = "";
        }

        window.SkolonMenuButton.render();
    }
}


function showAllThumbnailCards(cards: HTMLElement | null) {
    if (cards) {
        // All but 4 first hidden by default on xs screens:
        cards.querySelectorAll(".card").forEach(thumbnail => {
            thumbnail.classList.remove("d-none");
        })

        // All but 1st row hidden by default on all other sizes:
        cards.getElementsByClassName("row").item(0)?.classList.remove("preview");
    }
}


/**
 * Sets collapsible elements to collapsed/expanded depending on backend data.
 * Used in tandem with postCollapseStatus(). To use, make sure the
 * collapsible element (configured by the "data-bs-target" or "href"
 * attribute) has a suitably specific ID.
 */
function setCollapseStatus(root: ParentNode, collapseId: string, status: boolean) {
    const collapsible = root.querySelector(`#${collapseId}:not(.no-session)`);
    if (collapsible) {
        const collapse = Collapse.getOrCreateInstance(collapsible, { toggle: false });
        if (status) collapse.show();
        else collapse.hide();
    }
}


/**
 * Posts collapse status to backend when a collapsible element is shown or
 * hidden.
 */
function postCollapseStatus(event: Event) {
    const url = runtimeContext.urls.setCollapseStatus;

    if (
        url != null &&
        event.currentTarget instanceof HTMLElement &&
        event.currentTarget.id &&
        !event.currentTarget.classList.contains("no-session")
    ) {
        const formData = new FormData();
        formData.set("collapse_id", event.currentTarget.id);
        formData.set("status", event.type == "show.bs.collapse" ? "1" : "0");
        formData.set("csrfmiddlewaretoken", utils.getCsrfToken());
        navigator.sendBeacon(url, formData);
    }
}


/**
 * 1. Register callbacks for .collapse show/hide events
 * 2. Set initial shown/hidden status for .collapse elements according to
 *    data from backend
 */
function initCollapseStatus(root: Document | HTMLElement, collapseStatusContext: CollapseStatus) {
    root.querySelectorAll(".collapse").forEach(elem => {
        elem.addEventListener("show.bs.collapse", postCollapseStatus);
        elem.addEventListener("hide.bs.collapse", postCollapseStatus);
    });

    // Set initial collapse status for collapsible elements according to session data:
    for (const collapseId in collapseStatusContext) {
        setCollapseStatus(root, collapseId, collapseStatusContext[collapseId]);
    }
}


function initShowAllThumbnailsLinks() {
    document.querySelectorAll(".show-all-thumbnails").forEach(elem => {
        elem.addEventListener("click", event => {
            if (event.target instanceof Element) {
                event.preventDefault();
                showAllThumbnailCards(event.target.closest(".thumbnail-cards"));
                event.target.remove();
            }
        });
    });
}


function initContactUsForm() {
    const form = document.getElementById("contact-us-form");

    if (form instanceof HTMLFormElement) {
        const categories = form.elements.namedItem("category");

        if (categories instanceof RadioNodeList) {
            // When a category radio button is checked, display its
            // corresponding "radio-category" element and hide any others.
            categories.forEach(radio => {
                radio.addEventListener("change", event => {
                    form.querySelectorAll("[data-category]").forEach(elem => {
                        if (elem instanceof HTMLElement && event.target instanceof HTMLInputElement) {
                            if (elem.dataset.category == event.target.value) elem.classList.remove("d-none");
                            else elem.classList.add("d-none");
                        }
                    });
                })
            })
        }

        form.addEventListener("submit", event => {
            event.preventDefault();
            dynamic.fetchDjangoJson(form).then(() => {
                alerts.addSuccess(
                    gettext("Thanks for your input, we will hopefully reply as soon as possible.")
                );
                Modal.getOrCreateInstance("#contact-us-modal").hide();
                resetDirrtyForm(form);
            });
        });
    }
}


/**
 * First update History state with activeSchoolClassId if such a value exists
 * in the runtime context. Then set "active class" cookie, so backend can
 * hopefully get it right even when the user navigates back & forth between
 * "active classes" using browser buttons, without requesting anything from
 * backend.
 *
 * Setting this cookie in a "pageshow" event listener seems to result in this
 * code being run even when the entire page is fetched from browser cache.
 */
function initActiveSchoolClass() {
    if (runtimeContext.activeSchoolClassId != null) {
        utils.replaceState({"activeSchoolClassId": String(runtimeContext.activeSchoolClassId)}, location.href);
    }

    addEventListener("pageshow", () => {
        if (history.state?.activeSchoolClassId) {
            utils.setCookie("activeSchoolClassId", String(history.state.activeSchoolClassId));
        }
    });
}


/**
 * If the #recommend-poll element is in the DOM at all, that means it should
 * also be shown.
 */
function initRecommendPoll() {
    const recommendPoll = document.getElementById("recommend-poll");
    const post = (url: string, level?: string) => {
        const formData = new FormData();
        if (level) formData.set("level", level);
        navigator.sendBeacon(url, formData);
    };
    const msSinceLogin = runtimeContext.lastLogin ? Date.now() - Date.parse(runtimeContext.lastLogin) : 0;

    if (recommendPoll instanceof HTMLElement) {
        const url = recommendPoll.dataset.url;
        const closeButton = recommendPoll.querySelector(".btn-close");
        const content = recommendPoll.querySelector(".recommend-poll-content");
        const thanks = recommendPoll.querySelector(".recommend-poll-thanks");
        const levelButtons = recommendPoll.querySelectorAll(".level-button");
        const disableElements = () => {
            levelButtons.forEach(elem => utils.disableElement(elem));
            if (closeButton) utils.disableElement(closeButton);
        };

        if (url) {
            closeButton?.addEventListener("click", () => {
                disableElements();
                recommendPoll.classList.remove("show");
                post(url);
            });

            levelButtons.forEach(levelButton => {
                if (levelButton instanceof HTMLElement) {
                    levelButton.addEventListener("click", () => {
                        disableElements();
                        if (content && thanks) {
                            content.classList.add("d-none");
                            thanks.classList.remove("d-none");
                            setTimeout(() => { recommendPoll.classList.remove("show") }, 2000);
                        }
                        else recommendPoll.classList.remove("show");
                        post(url, levelButton.dataset.level);
                    });
                }
            });

            // Show poll min 20s after login:
            setTimeout(() => { recommendPoll.classList.add("show") }, 20000 - msSinceLogin);
        }
    }
}


function initTogglePasswordVisibility(root: Document | HTMLElement) {
    root.querySelectorAll(".toggle-password-visibility-container").forEach(container => {
        const iconContainer = container.getElementsByClassName("input-group-icon").item(0);
        const icon = container.getElementsByTagName("i").item(0);
        const input = container.getElementsByTagName("input").item(0);

        if (icon && iconContainer && input instanceof HTMLInputElement) {
            const popover = Popover.getOrCreateInstance(iconContainer);

            iconContainer.addEventListener("click", () => {
                if (input.type == "password") {
                    input.type = "text";
                    icon.classList.remove("fa-eye");
                    icon.classList.add("fa-eye-slash");
                    popover.setContent({".popover-body": gettext("Hide password")});
                }
                else {
                    input.type = "password";
                    icon.classList.add("fa-eye");
                    icon.classList.remove("fa-eye-slash");
                    popover.setContent({".popover-body": gettext("Show password")});
                }
            });
        }
    });
}


function showSidebar(onShown?: () => void) {
    const sidebar = document.getElementById("sidebar");

    if (sidebar && sidebar.classList.contains("hidden")) {
        document.addEventListener("transitionend", () => { onShown?.apply(null) }, { once: true });
        document.querySelectorAll(".toggle-sidebar").forEach(toggler => {
            if (toggler instanceof HTMLElement) {
                const arrowIcon = toggler.getElementsByClassName("toggle-sidebar-arrow").item(0);

                sidebar.classList.remove("hidden");
                if (arrowIcon) {
                    arrowIcon.classList.remove("fa-angles-right");
                    arrowIcon.classList.add("fa-angles-left");
                }
                toggler.title = gettext("Hide sidebar");
            }
        });
    }
    else onShown?.apply(null);
}


function hideSidebar() {
    const sidebar = document.getElementById("sidebar");

    if (sidebar) {
        document.querySelectorAll(".toggle-sidebar").forEach(toggler => {
            if (toggler instanceof HTMLElement) {
                const arrowIcon = toggler.getElementsByClassName("toggle-sidebar-arrow").item(0);

                sidebar.classList.add("hidden");
                if (arrowIcon) {
                    arrowIcon.classList.remove("fa-angles-left");
                    arrowIcon.classList.add("fa-angles-right");
                }
                toggler.title = gettext("Show sidebar");
            }
        });
    }
}


function initSidebarToggler() {
    const sidebar = document.getElementById("sidebar");

    if (sidebar) {
        document.querySelectorAll(".toggle-sidebar").forEach(toggler => {
            if (toggler instanceof HTMLElement) {
                toggler.addEventListener("click", () => {
                    const show = sidebar.classList.contains("hidden");

                    if (show) showSidebar();
                    else hideSidebar();

                    if (toggler.dataset.url) {
                        const formData = utils.createFormData({status: show ? "true" : "false"});
                        navigator.sendBeacon(toggler.dataset.url, formData);
                    }
                });
            }
        });
    }
}


function setMobileMenuOpen(status: boolean, onFinished: () => void) {
    if (!utils.windowReachesBreakpoint("lg")) {
        const element = document.getElementById("top-navbar");

        if (element != null) {
            const collapse = Collapse.getOrCreateInstance(element, { toggle: false });

            if (collapse._isShown() != status || collapse._isTransitioning) {
                document.getElementById("top-navbar")?.addEventListener(
                    status ? "shown.bs.collapse" : "hidden.bs.collapse",
                    () => { onFinished() },
                    { once: true },
                );
                if (status) collapse.show();
                else collapse.hide();
                return;
            }
        }
    }
    onFinished();
}


function setDropdownShown(elem: string | Element, status: boolean, onFinished: () => void) {
    const element = elem instanceof Element ? elem : document.querySelector(elem);

    if (element != null) {
        const dropdown = Dropdown.getOrCreateInstance(element);

        if (dropdown._isShown() != status) {
            element.addEventListener(
                status ? "shown.bs.dropdown" : "hidden.bs.dropdown",
                () => { onFinished() },
                { once: true },
            );
            if (status) dropdown.show();
            else dropdown.hide();
            return;
        }
    }
    onFinished();
}


function initTokenPDFForm() {
    document.querySelector("form#token-pdf-form")?.addEventListener("submit", event => {
        Modal.getOrCreateInstance("#token-pdf-modal").hide();
        event.stopImmediatePropagation();
    });
}


function initStartWebGLButton() {
    document.getElementById("start-webgl-button")?.addEventListener("click", () => {
        Modal.getOrCreateInstance("#start-webgl-modal").hide();
    });
}


function init(context: RuntimeContext) {
    if (context.environment != "production") DEBUG = true;
    runtimeContext = context;

    /** Things to do once DOM is loaded: */
    utils.onDOMLoaded(() => {
        initMobileNavbar();
        initSidebarToggler();
        initShowAllThumbnailsLinks();
        initContactUsForm();
        renderSkolonButton();
        initRecommendPoll();
        initTokenPDFForm();
        initStartWebGLButton();
    });

    /** Things to do once full page is loaded: */
    utils.onPageLoaded(document => {
        // Scroll to .scroll-on-load element if exists
        const elem = document.getElementsByClassName("scroll-on-load").item(0);
        if (elem instanceof HTMLElement) utils.scrollToElement(elem);

        // Init Bootstrap collapse status from session context data:
        dynamic.onDOMLoaded(root => initCollapseStatus(root, context.collapseStatus));
    });

    /** Things to do on document + future dynamically loaded content: */
    dynamic.onDOMLoaded(root => {
        // Init click events for elements with data-scroll-to:
        utils.initScrollToLinks(root);
        // Do init stuff for forms:
        root.querySelectorAll("form").forEach(initForm);
        // Disabled links
        root.querySelectorAll("a.disabled").forEach(elem => utils.disableElement(elem));
        // Init disable-on-click elements:
        utils.initDisableOnClick(root);
        // Init text-with-copy fields:
        initTextWithCopy(root);
        // Init image lazy loading:
        $(root).find("img.lazy").Lazy();
        // Init Bootstrap popovers:
        initPopovers(root);
        // Init "active" CSS classes:
        initActiveClasses(root, context.viewName, context.appName, context.path);
        // Set max-height for dropdowns that should have it:
        utils.setDropdownMaxHeights(root);
        initTogglePasswordVisibility(root);
    });

    initActiveSchoolClass();
    initWindowListeners();
    utils.initFontListener();
}
