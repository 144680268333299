export { addDanger, addSuccess, add, AlertType };


enum AlertType {
    Primary = "primary",
    Secondary = "secondary",
    Success = "success",
    Danger = "danger",
    Warning = "warning",
    Info = "info",
    Light = "light",
    Dark = "dark",
}


/**
 * @param parent Will use this element's closest .alerts descendant, or the
 * element itself if it has such a class. If undefined, use main #alerts.
 * @param temporary Whether this alert should be cleared when a new one is added.
 * @param fallbackToMain If elem is set, but was not an .alerts and did not
 * have an .alerts descendant, use the main #alerts instead.
 */
function add(type: AlertType, text: string, parent?: Element | null, temporary=true, fallbackToMain=true) {
    const mainAlerts = document.getElementById("alerts");
    let alerts: Element | null = null;

    if (parent) {
        alerts = parent.classList.contains("alerts") ? parent : parent.getElementsByClassName("alerts").item(0);
        if (!alerts && fallbackToMain) alerts = mainAlerts;
    }
    else alerts = mainAlerts;

    if (alerts) {
        const newAlert = document.createElement("div");
        newAlert.classList.add("alert", `alert-${type}`);
        if (temporary) newAlert.classList.add("temporary");
        newAlert.innerHTML = `<div class="alert-text">${text}</div>`;
        alerts.querySelectorAll(".alert.temporary").forEach(elem => elem.remove());
        alerts.appendChild(newAlert);
    }
}


function addDanger(text: string, parent?: Element | null, temporary=true, fallbackToMain=true) {
    add(AlertType.Danger, text, parent, temporary, fallbackToMain);
}


function addSuccess(text: string, parent?: Element | null, temporary=true, fallbackToMain=true) {
    add(AlertType.Success, text, parent, temporary, fallbackToMain);
}
