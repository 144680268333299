import * as dynamic from "./dynamic";
import * as utils from "./utils";

export { hide, show, init };


/**
 * @param elem Will use this element's closest .preloader descendant, or the
 * element itself if it has such a class. If undefined, use #preloader.
 * @param fallbackToMain If elem is set, but was not a .preloader and did not
 * have a .preloader descendant, show the main #preloader instead.
 */
function show(elem?: Element | EventTarget | null, fallbackToMain = false) {
    const mainPreloader = document.getElementById("preloader");
    let preloader = null;

    if (elem instanceof Element) {
        preloader = elem.classList.contains("preloader") ? elem : elem.getElementsByClassName("preloader").item(0);
        if (!preloader && fallbackToMain) preloader = mainPreloader;
    }
    else preloader = mainPreloader;

    if (preloader) {
        preloader.classList.add("show");
        if (preloader === mainPreloader) utils.hideVerticalScrollbar();
    }
}


/**
 * Hide all preloaders that are descendants of, or identical to, document/elem,
 * except if there is a .dynamic-container element in between them (in which
 * case it can be assumed that the preloader either is already hidden, or
 * should only be hidden once the dynamic content is loaded).
 */
function hide(elem?: Element | EventTarget | null) {
    const target = elem instanceof Element ? elem : document;
    const preloaders = Array.from(target.querySelectorAll(".preloader"));
    const dynamicContainers = Array.from(target.querySelectorAll(".dynamic-container"));
    const excluded = dynamicContainers.flatMap(e => Array.from(e.querySelectorAll(".preloader")));
    const toHide = preloaders.filter(preloader => !excluded.includes(preloader));

    if (target instanceof Element && target.classList.contains("preloader")) toHide.push(target);
    if (target instanceof Document) utils.showVerticalScrollbar();
    toHide.forEach(preloader => preloader.classList.remove("show"));
}


function init() {
    // Attempt to combat situations where the user navigates to a history
    // entry (via browser forward/back buttons) where the preloader was shown.
    window.addEventListener("pagehide", () => hide());

    dynamic.onDOMLoaded(root => {
        // Set up preloader on submit for forms that want it
        root.querySelectorAll("form.show-preloader").forEach(form => {
            form.addEventListener("submit", event => show(event.target, true));
        });

        // Shows main preloader when user clicks link with class
        // "show-preloader". Except it tries its best to detect if user is
        // opening link in new tab/window. In that case, do NOT show preloader.
        root.querySelectorAll("a.show-preloader:not([target=_blank])").forEach(elem => {
            elem.addEventListener("click", event => {
                if (
                    !(event instanceof MouseEvent) ||
                    !event.ctrlKey &&
                    !event.shiftKey &&
                    !event.metaKey &&  // Apple
                    !(event.button && event.button == 1)  // middle click
                ) show();
            });
        });
    });
}
