import { Modal } from "bootstrap";
import $ from "jquery";

import * as alerts from "./alerts";
import * as edulms from "./edulms";
import * as preloader from "./preloader";
import * as utils from "./utils";

export {
    fetchDjangoJson,
    init,
    initDynamicModalLinks,
    injectHTML,
    loadDynamicContent,
    onDOMLoaded,
    onElementLoaded,
    postSimpleAjaxForm,
};


interface DjangoErrors {
    __all__?: string[];
    [key: string]: string[] | undefined;
}


function shouldFetch(elem: HTMLElement): boolean {
    return (
        elem.classList.contains("dynamic-container") &&
        (
            elem.dataset.loaded === undefined ||
            elem.dataset.loaded === "false" ||
            elem.dataset.alwaysLoad !== undefined
        )
    );
}


function injectHTML(html: string, elem: HTMLElement, innerElem?: Element | null, append = false) {
    // We have to make do with Jquery here, because that's the easiest way to
    // get it to also execute any <script> content in the response.
    if (append) {
        if (innerElem) $(innerElem).append(html);
        else $(elem).append(html);
    }
    else {
        if (innerElem) $(innerElem).html(html);
        else $(elem).html(html);
    }
    elem.dispatchEvent(new Event("dynamicload", { bubbles: true }));
    elem.dataset.loaded = "true";
}


/**
 * Fetches from "data-content-url" URL. If `elem` contains a child with class
 * "dynamic-content", results will be put there. Otherwise, it will be put
 * directly into `elem` (replacing its current contents).
 *
 * If fetch was unsuccessful, and `silent` is false, display an alert in
 * elem's ".alerts" child if such a child exists, otherwise in the main
 * "#alerts".
 *
 * If `elem` contains a child with class="preloader", or has that class itself,
 * that element will be shown at init and hidden after load is complete
 * (regardless of whether the fetch was successful or not).
 *
 * Actions that need to be performed on the newly loaded content can be (and
 * are) hooked up to the "dynamicload" event. Or they can be done via
 * `.then()`.
 */
function loadDynamicContent<T extends HTMLElement>(
    elem: T,
    silent=false,
    emptyBefore=true
): Promise<LoadDynamicContentPromise<T>> {
    return new Promise<LoadDynamicContentPromise<T>>((resolve, reject) => {
        if (shouldFetch(elem) && elem.dataset.contentUrl) {
            const innerElem = elem.getElementsByClassName("dynamic-content").item(0);
            if (emptyBefore) {
                if (innerElem) innerElem.innerHTML = "";
                else elem.innerHTML = "";
            }
            preloader.show(elem);
            fetch(elem.dataset.contentUrl).then(response => {
                if (!response.ok) throw new Error(response.statusText);
                return response.text();
            }).then(html => {
                injectHTML(html, elem, innerElem);
                resolve({ elem: elem });
            }).catch(error => {
                if (edulms.DEBUG) console.error(
                    "loadDynamicContent: error",
                    error,
                    elem,
                    elem.dataset.contentUrl,
                )
                if (!silent) alerts.addDanger(String(error), elem);
                if (innerElem) innerElem.innerHTML = "";
                else elem.innerHTML = "";
                reject(error);
            }).finally(() => {
                preloader.hide(elem)
            });
        }
        else {
            // It will probably already be hidden, but just to be sure:
            preloader.hide(elem);
            resolve({ elem: elem });
        }
    });
}


/**
 * Will intercept a click event on `elem` and open a dynamic modal, if `elem`
 * has class="open-dynamic-modal", a "data-modal-id" attribute pointing to a
 * modal that exists in DOM, and a "data-modal-content-url" attribute.
 *
 * We only check the data- attributes once the click has actually occurred,
 * as they may change during the element's lifetime.
 */
function initDynamicModalLinks(root: HTMLElement | Document) {
    root.querySelectorAll(".open-dynamic-modal").forEach(elem => {
        if (elem instanceof HTMLElement && elem.dataset.modalId) {
            elem.addEventListener("click", event => {
                event.preventDefault();

                if (event.currentTarget instanceof HTMLElement) {
                    const modalId = event.currentTarget.dataset.modalId;
                    const modalContentUrl = event.currentTarget.dataset.modalContentUrl;

                    if (modalId && modalContentUrl) {
                        const modal = document.getElementById(modalId);
                        if (modal && modal.classList.contains("modal")) {
                            if (modal.dataset.contentUrl != modalContentUrl) {
                                modal.dataset.loaded = "false";
                                modal.dataset.contentUrl = modalContentUrl;
                            }
                            Modal.getOrCreateInstance(modal).show();
                        }
                    }
                }
            });
        }
    });
}


/**
 * To be used when we want some initialization to be done on all content, even
 * if it's added dynamically after page load.
 *
 * Will run callback with document as argument as soon as DOM is loaded.
 * Thereafter, whenever a dynamicload event is triggered on document, will
 * run callback on _the root element of the loaded content_.
 */
function onDOMLoaded(callback: (node: Document | HTMLElement, isDynamic: boolean) => any) {
    utils.onDOMLoaded(() => callback(document, false));
    document.addEventListener("dynamicload", event => {
        // .target = .dynamic-container element, .currentTarget = document
        callback(event.target, true);
    })
}


/**
 * If elem is a .dynamic-container whose contents have yet to be loaded:
 * run callback once dynamic load is ready. In all other cases, run callback
 * right now.
 */
function onElementLoaded<T extends HTMLElement>(elem: T, callback: (isDynamic: boolean) => any) {
    if (shouldFetch(elem)) {
        elem.addEventListener("dynamicload", event => {
            if (event.target == elem) callback(true);
        }, { once: true });
    }
    else callback(false);
}


/**
 * Submits a Django generated form, expects a JSON response. On success,
 * returns a promise with said JSON data. On error, there should be a JSON
 * object returned by Django with an "error" object inside it, where the keys
 * are either "__all__" (for non-field errors) or correspond to form fields.
 * Non-field errors will be displayed as alerts inside an .alerts element on
 * the field, if such an element exists; otherwise inside the main .alerts.
 * Field specific errors will be displayed adjacent to their fields in the
 * Bootstrap manner.
 */
function fetchDjangoJson(form: HTMLFormElement, url?: string, method?: string): Promise<any> {
    function handleDjangoErrors(errors?: DjangoErrors): boolean {
        let handled = false;
        let hasFieldErrors = false;
        for (const key in errors) {
            if (key == "__all__") {
                handled = true;
                errors[key]?.forEach(error => alerts.addDanger(error, form));
            }
            else {
                // Silly way to cover cases where there is a prefix:
                const field = form.querySelector(`[name=${key}]`) || form.querySelector(`[name$=-${key}]`);
                if (field) {
                    handled = true;
                    hasFieldErrors = true;
                    field.classList.add("is-invalid");
                    errors[key]?.forEach(error => {
                        const feedback = document.createElement("div");
                        feedback.classList.add("invalid-feedback");
                        feedback.textContent = error;
                        field?.after(feedback);
                    });
                }
            }
        }
        if (hasFieldErrors)
            alerts.addDanger(gettext("At least one field contains errors; see details below."), form);
        return handled;
    }

    function onError(error: any, reject: (reason?: any) => void) {
        alerts.addDanger(String(error), form);
        reject(error);
    }

    utils.clearDjangoFormErrors(form);
    url = url || form.action;
    method = method || form.method.toUpperCase();

    return new Promise((resolve, reject) => {
        fetch(url!, {
            method: method,
            body: new FormData(form),
        }).then(response => {
            response.json().then(data => {
                if (!response.ok) {
                    if (!handleDjangoErrors(data.errors)) {
                        alerts.addDanger(gettext("An unknown error occurred."), form);
                    }
                    reject(data.errors);
                }
                else resolve(data);
            }).catch(error => onError(error, reject));
        }).catch(error => {
            onError(error, reject);
        }).finally(() => {
            preloader.hide();
            edulms.setDirrtyFormClean(form);
        });
    });
}


/**
 * Posts `form` and expects a JSON response. If this response contains a
 * "message" property, this will be displayed as an alert in the main "alerts"
 * container. If the response contains a "success" boolean, the alert will be
 * of type "success" or "danger". Otherwise "info".
 */
function postSimpleAjaxForm(form: HTMLFormElement): Promise<any> {
    return new Promise((resolve, reject) => {
        fetch(
            form.action,
            { method: "POST", body: new FormData(form) }
        ).then(response => {
            if (!response.ok) throw new Error(gettext("An unknown error occurred."));
            return response.json();
        }).then(data => {
            if (data.message) {
                let alertType: alerts.AlertType;

                if (data.success == true) alertType = alerts.AlertType.Success;
                else if (data.success == false) alertType = alerts.AlertType.Danger;
                else alertType = alerts.AlertType.Info;

                alerts.add(alertType, data.message);
                resolve(data);
            }
        }).catch(error => {
            alerts.addDanger(String(error));
            reject(error);
        });
    });
}


function initSimpleAjaxForms(root: HTMLElement | Document) {
    root.querySelectorAll("form.simple-ajax-form").forEach(form => {
        if (form instanceof HTMLFormElement) {
            form.addEventListener("submit", event => {
                event.preventDefault();
                postSimpleAjaxForm(form);
            });
        }
    });
}


function init() {
    onDOMLoaded(root => {
        // Dynamically load arbitrary content on page load
        root.querySelectorAll(".dynamic-container.fetch-on-load").forEach(elem => {
            if (elem instanceof HTMLElement) loadDynamicContent(elem);
        });

        initDynamicModalLinks(root);
        initSimpleAjaxForms(root);

        /**
         * Dynamically load modal/collapse contents.
         *
         * The equality check between target and currentTarget is because the
         * show.bs.* events may have been triggered on a _descendant_ of the
         * .dynamic-container element and then propagated upwards. In that
         * case, we take no action.
         */
        root.querySelectorAll(".dynamic-container").forEach(elem => {
            elem.addEventListener("show.bs.modal", event => {
                if (event.target == event.currentTarget) loadDynamicContent(event.target);
            });
            elem.addEventListener("show.bs.collapse", event => {
                if (event.target instanceof HTMLElement && event.target == event.currentTarget)
                    loadDynamicContent(event.target);
            });
        })
    });
}
